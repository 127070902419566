<template>
    <div class="content-pages" >
        <h1 >{{ $t('titles.content_pages') }}</h1>
        <LoadedContent  :loaded="isLoaded" :error="isError">
            <BaseTable :columns="columns" :data="data" />
            <PageFooter>
                <BasePagination :getUrl="getPageUrl" :loadData="loadData"/>
                <div>
                    <RouterLinkLocal :link="'url.project/:project_code/url.contents'">
                        <AddItemButton :title="$t('titles.contents_overview')" :icon="'content_block'" />
                    </RouterLinkLocal>
                    <AddItemButton @click="openModal({})" />
                </div>
            </PageFooter>
        </LoadedContent>
        <teleport to="#app">
            <ContentPageForm :baseData="activeItem"  :opened="activeItem !== null" @close="closeModal()" />
        </teleport>
    </div>
</template>

<script>
import BaseTable from "@common/components/crud/BaseTable";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed, defineAsyncComponent, provide, ref,  } from 'vue';
import LoadedContent from '@common/components/base/LoadedContent';
import { useRouter } from 'vue-router';
import useProject from '@admin/composable/ProjectModel';
import BasePagination from '@common/components/crud/BasePagination';
import AddItemButton from '@admin/components/base/AddItemButton';
import PageFooter from '@admin/components/base/PageFooter';

export default {
    name: "ContentPages",
    components: {
        BaseTable,
        LoadedContent,
        ContentPageForm: defineAsyncComponent(() => import(/* webpackChunkName: "content-page-form" */'@admin/components/cms/content/ContentPageForm')),
        BasePagination,
        AddItemButton,
        PageFooter
    },
    setup(){
        provide("crud_store","content_page")

        const store = useStore();
 
        const i18n = useI18n();

        const router = useRouter()

        // loading data
        store.dispatch('language/fetchItems',{});

        const loadData = () => {
            let forBlock = router.currentRoute.value.name === 'content_pages_block';
            if(router.currentRoute.value.name !== 'content_pages' && router.currentRoute.value.name !== 'content_pages_overview' && !forBlock){
                return;
            }
            let page = 1;
            if(router.currentRoute.value.params.page_num){
                page = router.currentRoute.value.params.page_num;
            } 
            if(forBlock){
                let content = router.currentRoute.value.params.content;

                store.dispatch('content_page/fetchItems',{page: page,query: {'content': content}});
            } else {
                store.dispatch('content_page/fetchItems',{page: page,query: null});
            }
        };  
        loadData();
        //  watchEffect(() => loadData());

        // setting columns
        const { 
            hasExtension
        } = useProject();  
        const statusOptions = [
            {
                title: i18n.t('controls.active'),
                code: "20",
            },
            {
                title: i18n.t('controls.disabled'),
                code: "0",
            },
        ];
        if(hasExtension("auth")){
            statusOptions.push(
                {
                    title: i18n.t('cms.private_access'),
                    code: "10",
                },
                {
                    title: i18n.t('cms.only_public'),
                    code: "30",
                }
            )
        }

        
        const status = {
            header: "St.",
            header_title: i18n.t("controls.status"),
            type: 'button',
            key: "status",
            icon: (record) => {
                switch(record.status){
                    // active
                    case 20:
                        return 'green_circle'; 
                    // only public
                    case 30:
                        return 'green_empty_circle'; 
                    // private
                    case 10:
                        return 'orange_circle'; 
                    // inactive
                    case 0:
                    default:
                        return 'red_circle'; 
                }
            },
            getTitle: (record) => {
                switch(record.status){
                    // active
                    case 20:
                        return i18n.t('controls.active'); 
                    // only public
                    case 30:
                        return i18n.t('cms.private_access'); 
                    // private
                    case 10:
                        return i18n.t('cms.only_public');
                    // inactive
                    case 0:
                    default:
                        return i18n.t('controls.disabled'); 
                }
            },
            content: () => '',
            action: (record) => {
                let newStatus = 0;
                if(!hasExtension('auth')){
                    if(record.status === 0){
                        newStatus = 20;
                    }
                } else {
                    if(record.status === 0){
                        newStatus = 10;
                    } else if(record.status === 10){
                        newStatus = 20;
                    } else if(record.status === 20){
                        newStatus = 30;
                    }
                }
                store.dispatch('content_page/updateItem',{code: record.code,data: {status: newStatus}});
            },
            filter_select: statusOptions,
            class: "w-xs",
        }

        // const languages = computed(() => {
        //     let langs = computed(() =>store.getters['language/getItems']);
        //     let options = [];
        //     langs.value.forEach(element => {
        //         let code = element.item_key;
        //         let title = element.name;
        //         options.push({code,title});
        //     });
        //     console.log(options);
        //     return options;
        // })

        const columns = [
            {
                type: "link",
                icon: () => "go_to_page",
                title:  i18n.t('cms.go_to_page'),
                content: () => '',
                link: (record) => 'url.project/:project_code/url.content_pages/!'+record.code,
            },
            {
                type: "label",
                content: () => "",
                icon: (record) => {
                    if(!record.homepage){
                        return false;
                    }
                    return 'home'
                },
                // class: "no-padding"
            },
            {
                type: "edit_record",
                action: (record) => openModal(record),
            },
            { 
                type: "text",
                header: i18n.t("cms.title"),
                filter: true,
                key: "title",
                title:i18n.t("cms.title"),
                class: "w-xl",
                // disabled: true,
            },
            { 
                type: "text",
                header: i18n.t("cms.header"),
                filter: true,
                key: "header",
                class: "w-xl",
                title:i18n.t("cms.header"),
                // disabled: true,
            },
            { 
                type: "text",
                header: i18n.t("cms.url"),
                filter: true,
                key: "seo.url",
                class: "w-xl",
                title:i18n.t("cms.url"),
                // disabled: true,
            },
            { 
                type: "select",
                header: i18n.t("cms.language"),
                // filter: true,
                key: "language",
                // filter_key: 'language0.name',
                filter_select: true, //languages.value,
                filter_code: 'item_key',
                filter_title: "name",
                options_store: 'language/getItems',
                options: ['name','item_key'],
                code: 'item_key',
                title:i18n.t("cms.language"),
                // disabled: true,
            },
            {
                type: "loader"
            },
            status,
            // { 
            //     type: "delete",
            // },
        ];

        // getting data
        const data = computed(() => store.getters['content_page/getItems']);

        const isLoaded = computed(() => store.getters["content_page/isLoaded"]);
        
        const isError = computed(() => store.getters["content_page/isError"]);

        // modal window
        const activeItem = ref(null);
        const openModal = (menuItem) => {
            activeItem.value = menuItem;
        }
        const closeModal = () => {
            activeItem.value = null;
        }

        // pagination
        const getPageUrl = (number) => {
            return 'url.project/:project_code/url.content_pages/url.overview/!'+number;
        }
      
        return {
            columns,
            data,
            isLoaded,
            isError,
            closeModal,
            activeItem,
            openModal,
            getPageUrl,
            loadData
        }

    }
}
</script>

<style scoped>
.content-pages,.loaded-content{
    display: flex;
    flex-direction: column;
    height: 100%;
}
::v-deep(.base-table){
    margin: 1rem;
    border-collapse: separate;
    border-spacing: 0 0.2rem;
}
::v-deep(.base-table-row:nth-child(odd) td) {
    background: #fff6e6;
}

::v-deep(.base-table-row:nth-child(even) td) {
    background: #ffe7bb;
}
::v-deep(.base-table-row td) {
    padding: 0.5rem 0.2rem;
}
::v-deep(.base-table-row td.no-padding) {
    padding: 0;
}
::v-deep(.base-table td:first-child) { 
    border-top-left-radius: 1rem; 
    border-bottom-left-radius: 1rem;
    padding: 0.5rem 0.2rem 0.5rem 0.5rem;
}
::v-deep(.base-table td:last-child) { 
    border-top-right-radius: 1rem; 
    border-bottom-right-radius: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
}
::v-deep(.loader .base-loader) {
    left: 2rem;
    top:0;
}

</style>